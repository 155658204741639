exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-partner-pre-registration-page-tsx": () => import("./../../../src/templates/PartnerPreRegistrationPage.tsx" /* webpackChunkName: "component---src-templates-partner-pre-registration-page-tsx" */),
  "component---src-templates-region-closed-partner-list-page-tsx": () => import("./../../../src/templates/RegionClosedPartnerListPage.tsx" /* webpackChunkName: "component---src-templates-region-closed-partner-list-page-tsx" */),
  "component---src-templates-region-partner-list-page-tsx": () => import("./../../../src/templates/RegionPartnerListPage.tsx" /* webpackChunkName: "component---src-templates-region-partner-list-page-tsx" */),
  "component---src-templates-support-browse-region-page-tsx": () => import("./../../../src/templates/SupportBrowseRegionPage.tsx" /* webpackChunkName: "component---src-templates-support-browse-region-page-tsx" */),
  "component---src-templates-support-partner-page-tsx": () => import("./../../../src/templates/SupportPartnerPage.tsx" /* webpackChunkName: "component---src-templates-support-partner-page-tsx" */)
}

